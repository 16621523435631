<template>
  <section>
    <h3 class="samp-title">Sample Size Calculation: Mortality</h3>
    <div class="mort-details">
      <Summary v-for="(details, key) in mortDetails" :details="details" :key="details" class="m-r-2-5" :title="key"/>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import Summary from '@/components/SurveyDetails/Summary/index.vue';

export default defineComponent({
  name: 'Mortality',
  components: {
    Summary,
  },
  computed: {
    mortDetails() {
      const {
        mortalityHouseholdIncluded,
        mortalityPopulationIncluded,
      } = this.$store.getters['survey/getSampleSizeMortality'];
      return {
        'POPULATION TO BE INCLUDED': mortalityPopulationIncluded,
        'HOUSEHOLDS TO BE INCLUDED': mortalityHouseholdIncluded,
      };
    },
  },
});
</script>

<style>
  .mort-details {
    display: flex;
  }
</style>
