<template>
  <section class="meta-sample">
    <h2>Sampling</h2>
    <div class="sample-sum">
      <Anthropometry/>
      <div class="v-divider"/>
      <ClusterCalculation/>
      <Mortality class="mortality"/>
      <ClusterAssignmentTable class="clusterTable"/>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import Anthropometry from './Anthropometry.vue';
import Mortality from './Mortality.vue';
import ClusterCalculation from './ClusterCalculation.vue';
import ClusterAssignmentTable from './ClusterAssignmentTable.vue';

export default defineComponent({
  name: 'Sampling',
  components: {
    Anthropometry,
    Mortality,
    ClusterCalculation,
    ClusterAssignmentTable,
  },
});
</script>

<style>
  .meta-sample {
    padding: 1.5rem 0;
  }
  .sample-sum .samp-title {
    font-size: 1.25rem;
    line-height: 1.375rem;
    letter-spacing: -0.05px;
    color: #363534;
    padding: 1.5rem 0 2rem;
    margin: 0;
  }
  .sample-sum {
    display: grid;
    grid-template-columns: 0.8fr 0.1fr 0.8fr;
    grid-row-gap: 2rem;
  }
  .sample-sum .stat-box {
      margin-right: 2.5rem;
  }
  .v-divider {
    border-left: solid 1px #979797;
    height: 50rem;
    opacity: 0.25;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 2;
  }
  .mortality {
    grid-row: 2;
  }
</style>
