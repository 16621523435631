<template>
  <section>
    <h3 class="samp-title">Cluster Calculation</h3>
    <div class="samp-details">
      <Statistic title="NUMBER OF CLUSTERS" :percent="clusterCalc.clustersReachedPercent" :amount="clusterCalc.clustersReached" color="#dd5d34" type="Clusters"/>
      <div class="samp-sum">
        <Summary :key="title" v-for="(details, title) in clusterDetails" :title="title" :details="details"/>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Statistic from '../../Statistic/index.vue';
import Summary from '../../Summary/index.vue';

export default defineComponent({
  name: 'ClusterCalculation',
  components: {
    Statistic,
    Summary,
  },
  computed: {
    ...mapGetters('survey', {
      clusterCalc: 'getClusterCalculation',
    }),
    clusterDetails() {
      const {
        householdPerDayTeam,
        numberOfClusters,
      } = this.$store.getters['survey/getClusterCalculation'];
      return {
        'NUMBER OF HH PLANNED / DAY / TEAM': householdPerDayTeam ?? 'N/A',
        'NUMBER OF CLUSTERS PLANNED': numberOfClusters ?? 'N/A',
      };
    },
  },
});
</script>

<style>

</style>
