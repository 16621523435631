<template>
  <section>
    <h3 class="samp-title">Sample Size Calculation: Anthropometry</h3>
    <div class="mort-details">
      <Statistic title="SUBJECTS INCLUDED" :percent="Anthro.subjectsReachedPercent" :amount="Anthro.subjectsReached" type="Subjects"/>
      <div class="samp-sum">
        <Summary :key="title" v-for="(details, title) in anthroDetails" :title="title" :details="details"/>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Statistic from '../../Statistic/index.vue';
import Summary from '../../Summary/index.vue';

export default defineComponent({
  name: 'Anthropometry',
  components: {
    Statistic,
    Summary,
  },
  computed: {
    ...mapGetters('survey', {
      Anthro: 'getSampleSizeAnthropometry',
    }),
    anthroDetails() {
      const {
        includedAgeGroup,
        anthroSubjectsToInclude,
        anthroHouseholdsToInclude,
      } = this.$store.getters['survey/getSampleSizeAnthropometry'];
      return {
        'INCLUDED AGE GROUPS': includedAgeGroup ?? 'N/A',
        'SUBJECTS TO BE INCLUDED': anthroSubjectsToInclude ?? 'N/A',
        'HOUSEHOLDS TO BE INCLUDED': anthroHouseholdsToInclude ?? 'N/A',
      };
    },
  },
});
</script>

<style>
  .samp-details {
    display: flex;
  }
</style>
