<template>
  <section class="samp-cluster">
    <h3 class="samp-title">Cluster Assignment Table</h3>
    <p>Total Population Size: &nbsp;</p><span data-test="pop-size" class="font-reg"> {{ getClusterTable.totalPopulationSize || 'N/A'}}</span>
    <div class="samp-container">
      <table class="samp-table">
        <tr class="samp-table-title">
          <th>GEOGRAPHICAL UNIT</th>
          <th>POP. SIZE</th>
          <th>CLUSTER</th>
          <th>LOCATION</th>
        </tr>
        <tr v-for="(cluster, index) in getClusterTable.clusters" :key="index">
          <td>{{ cluster.unitName }}</td>
          <td>{{ cluster.population }}</td>
          <td><span v-for="(name, index) in cluster.name" :key="index">{{ name }}{{ cluster.name.length - 1 === index ? '' : ',' }}</span></td>
          <td>{{ cluster.location }}</td>
        </tr>
        <tr v-if="getClusterTable.clusters.length === 0">
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>
      </table>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'ClusterAssignmentTable',
  computed: {
    ...mapGetters('survey', ['getClusterTable']),
  },
});
</script>

<style>
  .samp-cluster {
    font-size: 1rem;
    color: #363534
  }
  .samp-cluster p{
    font-weight: 500;
    color: #8e908f;
    letter-spacing: -0.4px;
    line-height: 1.5rem;
    font-size: 1rem;
    display: inline-block;
  }
  .samp-table-title {
    height: 2.69rem;
    padding: 1rem;
    font-size: 0.813rem;
    line-height: 0.938rem;
    font-weight: bold;
    letter-spacing: 0.33px;
    background-color: #f0f1f0;
    border-color: solid 1px rgba(204, 204, 204, 0.7);
  }
  .samp-table {
    width: 100%;
  }
  .samp-table th, .samp-table td{
    padding-right: 1rem;
    padding-left: 1rem;
    height: 1.8rem;
  }
  .samp-table td {
    font-size: 1rem;
    font-family: 'DINNextLTPro-Regular';
    height: 1rem;
  }
  .samp-table tr {
    border: solid 1px rgba(204, 204, 204, 0.7);
  }
  .samp-container {
    max-height: 20.8rem;
    overflow:auto;
  }
</style>
